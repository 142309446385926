<template>
  <div
    ref="designer"
    style="position: absolute; left: 0; right: 0; top: 100px; bottom: 0"
    data-bind="dxReportDesigner: $data"
  />
</template>

<script>
import ko from 'knockout'
import 'devexpress-reporting/dx-reportdesigner'

export default {
  name: 'ReportingEngine',
  mounted() {
    const designerOptions = {
      reportUrl: ko.observable('ChargeVisitGroupReport'), // The URL of a report that is opened in the Report Designer when the application starts.
      requestOptions: {
        host: 'https://localhost:44316/',
        getDesignerModelAction: 'EmployeeDashboardApi/GetReportDesignerModel',
      },
    }
    ko.applyBindings(designerOptions, this.$refs.designer)
  },
  beforeDestroy() {
    ko.cleanNode(this.$refs.designer)
  },
}
</script>
